import { MdCancel } from "react-icons/md";
 import { BsCardImage } from "react-icons/bs";
 import { IoAddSharp } from "react-icons/io5";

const LogoUploader = (props) => {
  var { file, setfile, refreshfile } = props.hook;

  const pickfile = () => {
    document.getElementById("selectlogo")?.click();
  };
  return (
    <div className="my-2">
      {props.title && (
        <div className="flex items-center justify-between">
          <p className="text-xs mb-1">{props.title}</p>
        </div>
      )}

      <div className="bg-gray-50 rounded flex items-center w-full border justify-between">
        <div
          onClick={pickfile}
          className="bg-black rounded w-[30%] flex justify-center items-center p-3 text-white font-light text-xs  cursor-pointer"
        >
          <BsCardImage className=" mr-2" />
          Select file
        </div>
        <p className="text-sm font-light jutify-self-start cursor-pointer ml-3 truncate ">
          {!(file == undefined) ? file.name : ""}
        </p>
        {!(file == undefined) && (
          <MdCancel
            onClick={() => {
              refreshfile();
            }}
            size={20}
            className="mr-2 "
          />
        )}
        <input
          onChange={(event) => {
            const files = event.target.files;
            const file = files[0];
            props.hook.setfile(file);
          }}
          id="selectlogo"
          type="file"
          className=" hidden "
        />
      </div>
    </div>
  );
};

export { LogoUploader };
