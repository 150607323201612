import React, { useState, useEffect } from "react";
import "./PhotoSlider.css";
import { colors } from "../constants/theme";

const PhotoSlider = ({ photos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [photos.length]);
  const yellowText = "text-3xl font-bold text-yellow-500";
  const whiteText = "text-3xl font-bold text-white";
  return (
    <div className="photo-slider -z-10">
      {photos.map((photo, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? "active" : ""}`}
        >
          <img style={{ width: "100%" }} className="slieimg" src={photo.url} alt="Slider" />
          <div className="text-overlay self-end lg:m-12 ">
            <h1 className="md:text-5xl text-2xl font-bold leading-8">
            Environment, Crops,<br/> Trees, Livestock, People: <br />{" "}
              <h1 style={{ color: colors.primary }}>Interconnected</h1>
            </h1>
 
          </div>

          {/* {index === 0 ? (
            <div className="text-overlay">
              <p className={yellowText}>
                {photo.yellowText}:{" "}
                <span className="text-white">{photo.text1}</span>
              </p>
              <p className={whiteText}>{photo.text2}</p>
            </div>
          ) : index === 1 ? (
            <div className="text-overlay">
              <p className={whiteText}>{photo.text1}</p>
              <p className={yellowText}>{photo.yellowText}</p>
              <p className={whiteText}>{photo.text2}</p>
            </div>
          ) : index === 2 ? (
            <div className="text-overlay">
              <p className={yellowText}>{photo.yellowText}</p>
              <p className={whiteText}>{photo.text1}</p>
              <p className={whiteText}>{photo.text2}</p>
            </div>
          ) : index === 3 ? (
            <div className="text-overlay">
              <p className={yellowText}>
                {photo.yellowText} <span>The</span>
              </p>
              <p className={whiteText}>{photo.text1}</p>
              <p className={whiteText}>{photo.text2}</p>
            </div>
          ) : null} */}
        </div>
      ))}
    </div>
  );
};

export default PhotoSlider;
