import { resource_url } from "../constants/data";
import axios from "axios";

const ongetalldoc = async () => {
  try {
    const response = await axios.get(`${resource_url}/documents`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onAdddocument = async (postData) => {
  try {
    const response = await axios.post(`${resource_url}/adddocument`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onDeleteDoc = async (id) => {
  try {
    const response = await axios.delete(`${resource_url}/deletedocument/${id}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onUploadDoc = async (postData) => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: postData,
    redirect: "follow",
  };

  var response = await fetch(`${resource_url}/upload`, requestOptions);
  var jsonresponse = await response.json();

  return jsonresponse;
};

export { ongetalldoc, onUploadDoc, onDeleteDoc, onAdddocument };
