import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import Home from "../components/About/index";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { headerblog } from "../constants/import";
export default  () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="w-full">
                <Navbar />
                <div className="home flex justify-center items-center" style={{ height: "250px", width: "100%" }}>
        <p className="font-bold text-4xl text-white z-10 text-center py-16">
          Blog
        </p>
      </div>      <div>
        <iframe
          title="SCL Project Juriya - Year 1 FAs Performance Dashboard"
         width="100%"
          height="600"
          src="https://blog.sclng.com/"
          frameborder="0"
          allowFullScreen="true">
        </iframe>
      </div>
    </div>
  
  )
};

 
