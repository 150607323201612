import "./assets/css/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Home,
  About,
  Products,
  Gallery,
  Academy,
  Contact,
  FieldAgent,
  PowerbiYearOne,
  Survey,
  Acresal,
  FieldAgentListings,
  PowerbiYearTwo,
} from "./pages";
import Abt from "./pages/Abt";
import NotFound from "./pages/NotFound";
import Resources from "./pages/Resources";
import Greentech from "./components/Greentech";
import PowerbiYearTwoFAassessment from "./pages/PowerbiYearTwoFAassessment";
import PowerbiYearOneFAassessment from "./pages/PowerbiYearOneFAassessment";
import Blog from "./pages/Blog";
 
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<Abt />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/products" element={<Products />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/academy" element={<Academy />} />
          <Route path="/contact-us" element={<Contact />} />

          <Route path="/field-agent-register" element={<FieldAgent />} />
          <Route
            path="/project-juriya-dashboard-year-one"
            element={<PowerbiYearOne />}
          />  
          
          <Route
          path="/greentech"
          element={<Greentech />}
        />


          <Route
            path="/project-juriya-dashboard-year-two"
            element={<PowerbiYearTwo />}
          />
          <Route
            path="/project-juriya-dashboard-year-two-fa-assesment"
            element={<PowerbiYearTwoFAassessment />}
          />
          <Route
            path="/project-juriya-dashboard-year-one-fa-assesment"
            element={<PowerbiYearOneFAassessment />}
          />

          <Route path="/project-acresal" element={<Acresal />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/agent-listings" element={<FieldAgentListings />} />
          {/* The catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
