import React from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { TableData } from "./Table";
import { FaArrowDown } from "react-icons/fa";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { RiLoader2Fill } from "react-icons/ri";

import ViewDocModal from "./ViewDocModal";
import DeletedocModal from "./DeletedocModal";
export default ({ IsSame, pin ,refresh, loading ,resources }) => {
  const tbHeader = ["ID", "Title", "Description"];
  const [showViewdoc, setshowViewdoc] = useState(false);
  const [item, setitem] = useState("");
  const [showdelete, setshowdelete] = useState(false);
 

  return (
    <div className="overflow-x-auto">
      {showViewdoc && <ViewDocModal refresh={refresh} src={item.url} show={setshowViewdoc} />}
      {showdelete && <DeletedocModal refresh={refresh} item={item} show={setshowdelete} />}

      <table className="w-full bg-white">
        <thead className="w-full bg-[#EAECF0]">
          <tr className="w-full">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
              >
                {item}
              </th>
            ))}

            <th
              scope="col"
              className="px-6 py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
            >
              {"Actions"}
            </th>
          </tr>
        </thead>

        {loading && (
          <RiLoader2Fill size={40} className="text-green-500 animate-spin" />
        )}
        {/* Table content */}
        <tbody className="w-full bg-white">
          {resources.map((item, index) => (
            <tr
              className="bg-white border-b pt-8  hover:bg-gray-50"
              key={index}
            >
              <TableData item={item?.id} />
              <TableData item={item?.title} />
              <TableData item={item?.desc} />
              <td>
                <div className="flex items-center px-6 py-3">
                  <MdOutlineRemoveRedEye
                    onClick={() => {
                      setitem(item);
                      setshowViewdoc(true);
                    }}
                    className=" text-blue-600 mx-4 text-lg"
                  />

                  <FaArrowDown
                    className="text-green-500 text-lg cursor-pointer"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = item.url;
                      link.download = item.url.split("/").pop(); // Optional: Extract filename from URL
                      link.click();
                    }}
                  />
                {pin =="218945" &&  <MdDelete
                    className="text-red-500 mx-4 text-lg cursor-pointer"
                    onClick={() => { setitem(item)
                      setshowdelete(true)  }}
                  />}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Table Footer (Pagination) */}
      {/* Table Footer (Pagination) */}
      <div className="w-full flex items-center justify-between">
        {/* Numbers */}
        <div>
          <p className="sub-heading text-black">{`${1} of ${1} Pages`}</p>
        </div>
        {/* NExt and Previous Btn */}
        {/* Pagination */}
        <div className="px-6 py-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={() => {}}
            containerClassName={"inline-flex items-center -space-x-px"}
            pageClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 border border-gray-300 hover:text-gray-700 hover:bg-purple-300 hover:text-white"
            }
            pageLinkClassName={""}
            previousClassName={
              "block px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-purple-400 hover:text-white"
            }
            previousLinkClassName={""}
            nextClassName={
              "block px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-purple-400 hover:text-white"
            }
            nextLinkClassName={""}
            breakClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            breakLinkClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-purple-400 hover:text-white"
            }
            activeClassName={
              "z-10 px-3 py-2 leading-tight text-white border border-primary bg-primary hover:bg-purple-100 hover:text-primary"
            }
          />
        </div>
      </div>
    </div>
  );
};
