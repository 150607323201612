import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import Home from "../components/Gallery/Home";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { ongetalldoc } from "../services/resources";
import ResourceTable from "../components/resources/ResourcesTable";
import { IoAddSharp } from "react-icons/io5";
import UploadDocModal from "../components/resources/UploadDocModal";
import { toast } from "react-toastify";
import { colors } from "../constants/theme";

const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [resources, setresources] = useState([]);
  const [showupload, setshowupload] = useState(false);
  const [pin, setPin] = useState("");
  useEffect(() => {}, []);
  function onAcess(e) {
    console.log(pin);
    e.preventDefault();
    if (pin === "2189") {
      setHasAccess(true);
      toast.success("Access Granted");
    }
    
    else if (pin =="218945") {
      setHasAccess(true);
      toast.success("Access Granted"); 
    }
    else {
      toast.error("Invalid Access Pin");
    }
  }
  const getalldoc = async () => {
    setLoading(true);
    var res = await ongetalldoc();
    setLoading(false);

    setresources(res);
  };

  useEffect(() => {
    getalldoc();
  }, []);
  return (
    <>
      {!hasAccess ? (<>
        <Navbar />{" "}
        <div className="home flex justify-center items-center" style={{ height: "250px", width: "100%" }}>
        <p className="font-bold text-4xl text-white z-10 text-center py-16">
        Resources
        </p>
      </div>
        <div className="w-full h-[600px] flex-row justify-center items-center">
          <center>
            <div className="w-[50%] py-40">
              <h1 className="text-xl text-black">
                Please Enter Pin to authorize access
              </h1>
              <form>
                <div className="my-3">
                  <label class="block">
                    <span class="block text-sm font-medium text-slate-700 text-left">
                      Access Pin
                    </span>
                    <input
                      type="text"
                      class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                      placeholder="Access Pin"
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </label>
                </div>
                <button
                  className=" font-semibold rounded-lg py-2 justify-center w-full text-white flex items-center"
                  style={{ backgroundColor: colors.primary }}
                  onClick={onAcess}
                >
                  {"Get Access"}
                </button>
              </form>
            </div>
          </center>
        </div>
        <Footer />
        </>
      ) : (
        <div>

<div className="home flex justify-center items-center" style={{ height: "250px", width: "100%" }}>
        <p className="font-bold text-4xl text-white z-10 text-center py-16">
Resources        </p>
      </div>
          {showupload && (
            <UploadDocModal refresh={getalldoc} show={setshowupload} />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <Navbar />{" "}
              <div className=" px-6 py-4 ">
                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="text-xl font-bold text-black  mt-4">
                      Downloadable Forms
                    </h1>
                    <h3 className=" font-semibold mb-4 text-sm">
                      Find below the list of forms available for download.
                    </h3>
                  </div>

                 {pin =="218945" &&  <button
                    onClick={() => {
                      setshowupload(true);
                    }}
                    className="px-3 py-1 rounded bg-green-400 text-white font-light"
                  >
                    <IoAddSharp className=" inline" /> Upload
                  </button>}
                </div>
                <ResourceTable
                pin={pin}
                  refresh={getalldoc}
                  loading={loading}
                  resources={resources}
                />
              </div>
              <Footer />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Gallery;
