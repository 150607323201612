  import { useState } from "react";
import Spinner  from "../../components/Spinner";
 import { IoIosCloseCircleOutline } from "react-icons/io";

export default ({ src, show }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className=" fixed top-0 left-0 w-screen flex h-screen z-[50] bg-[#3f3f3f5d] justify-center items-center">
 


      <div className="w-[50%] h-[80vh] relative bg-white rounded px-6 py-8">
        <IoIosCloseCircleOutline
          size={30}
          onClick={() => show(false)}
          className=" absolute  top-1 right-5 p-1"
        />
        <iframe
          title={"Document view"}
          className="w-full h-full border border-gray-300"
          src={src}
        />
      </div>
    </div>
  );
};
