import React from "react";
import { Controller } from "react-hook-form";

 
const InputField = ({
  control,
  name,
  rules = {},
  placeholder,
  title,
  type,
  maxLength,
  showCount,
  isTextArea,
  disabled,
  readonly,
  isTrim,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="my-2">
          {title && (
            <div className="flex items-center justify-between">
              <p className="text-xs mb-2">{title}</p>
              <div>
                {showCount && (
                  <p className="text-xs">{`${
                    value?.length > 0 ? value?.length : 0
                  }${maxLength ? "/" + maxLength : ""}`}</p>
                )}
              </div>
            </div>
          )}
          {isTextArea ? (
            <textarea
              value={isTrim ? value?.trim() : value}
              placeholder={placeholder}
              onChange={(text) => onChange(text)}
              className={`block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-300"
              }`}
              maxLength={maxLength || undefined}
              rows={3}
            ></textarea>
          ) : // Conditionally render a date input if the type is set to "date"
          type === "date" ? (
            <input
              disabled={disabled}
              type="date"
              value={value}
              onChange={(event) => onChange(event.target.value)}
              className={`block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-300"
              }`}
              readOnly={readonly}
            />
          ) : // Conditionally render a time input if the type is set to "time"
          type === "time" ? (
            <input
              disabled={disabled}
              type="time"
              value={value}
              onChange={(event) => onChange(event.target.value)}
              className={`block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-300"
              }`}
              readOnly={readonly}
            />
          ) : (
            // Render a regular text input by default
            <input
              type={type || "text"}
              disabled={disabled}
              value={value}
              placeholder={placeholder}
              onChange={(text) => onChange(text)}
              className={`block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-300"
              }`}
              maxLength={maxLength || undefined}
              readOnly={readonly}
            />
          )}
          {error && (
            <span className="text-red-500 text-xs self-stretch">
              {error.message || "Error"}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default InputField;
