import React from "react";
// import slider1 from "../assets/images/slider1.png";
import slider1 from "../assets/images/slider1.png";
import slider2 from "../assets/images/slider2.png";
import slider3 from "../assets/images/slider3.png";
import slider4 from "../assets/images/slider4.png";
import slider5 from "../assets/images/slider5.png";
import slider6 from "../assets/images/slider6.png";

import PhotoSlider from "./PhotoSlider";
const Hero = () => {
  const photos = [
    { url: slider1, yellowText: "Seed", text1: "The", text2: "Genesis of all" },
    {
      url: slider2,
      yellowText: "Tapestry of Agriculture",
      text1: "Nurturing the Harmonious",
      text2: "Livestock, and Humanity",
    },
    {
      url: slider3,
      yellowText: "Cultivating Excellence",
      text1: "Embracing the Best",
      text2: "Agronomical Practices",
    },
    {
      url: slider4,
      yellowText: "Passion:",
      text1: "The Seed from Which",
      text2: "We Grow",
    },
    {
      url: slider5,
      yellowText: "",
      text1: "",
      text2: "",
    },
    {
      url: slider6,
      yellowText: "",
      text1: "",
      text2: "",
    },
  ];
  return (
    <div className=" ">
      
      <PhotoSlider photos={photos} />
    </div>
  );
};

export default Hero;
