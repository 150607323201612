  import { useState } from "react";
import Spinner  from "../../components/Spinner";
 import { IoIosCloseCircleOutline } from "react-icons/io";
import InputField from './InputField'
import useFileUploadhook from './useFileUploadhook'
import { useForm } from "react-hook-form";
import {LogoUploader} from './LogoUploader'
import {toast} from 'react-toastify'
import { IoAddSharp } from "react-icons/io5";
import {onUploadDoc,onAdddocument} from '../../services/resources'
export default ({ src, refresh, show }) => {
  const [loading, setLoading] = useState(false);
const {control, handleSubmit} =useForm()
var photouploadhook = useFileUploadhook()


const submit = async (data) => {
 
  
      setLoading(true);

      let formdata=   new FormData()
   
      formdata.append( 'file', photouploadhook.file)
  
   
     if (!photouploadhook.file) {
        setLoading(false)
        toast.error("document cannot be empty")
  
        return
      }
  
  
  
  
      try {
        let res = await onUploadDoc(formdata);
      let res2 = await onAdddocument({title:data.title, desc:data.desc, url:res.url})
      toast("File added successfuly!")
      refresh()
      show(false)
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
      setLoading(false);
  
  
  
  
   
  
    }
  return (

    
    <div className=" fixed top-0 left-0 w-screen flex h-screen z-[50] bg-[#3f3f3f5d] justify-center items-center">
 {loading && <Spinner/>}
 
  <div className="w-[30%] h-[70vh] relative bg-white rounded p-8">

      <h1 className="text-lg font-semibold text-black  mt-4">Upload a forms</h1>
      <h3 className=" font-light mb-4 text-sm">This form will be available to others for download.</h3>

        <IoIosCloseCircleOutline
          size={30}
          onClick={() => show(false)}
          className=" absolute  top-1 right-5 p-1"
        />
         <div className="flex flex-col">

         <InputField
                    name="title"
                    title="Title"
                    placeholder="Title of document"
                    control={control}
                    rules={{
                      required: "Title is required",
                    }}
                  />

         <InputField
                    name="desc"
                    title="Description"
                    isTextArea={true}
                    placeholder="Description of document"
                    control={control}
                    rules={{
                      required: "Description is required",
                    }}
                  />

<LogoUploader hook={photouploadhook} title='Select file' />

<button   onClick={handleSubmit(submit)} className="px-3 py-1 self-end rounded mt-6 bg-green-400 text-white font-light">
<IoAddSharp className=" inline"/> Upload
</button>
          </div>
      </div>
    </div>
  );
};
