import { useState } from "react";
import Spinner from "../Spinner";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { onDeleteDoc } from "../../services/resources";
import { RiLoader2Fill } from "react-icons/ri";

export default ({ item,refresh, show }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className=" fixed top-0 left-0 w-screen flex h-screen z-[50] bg-[#3f3f3f5d] justify-center items-center">
      <div className="w-[30%] h-[40vh] relative bg-white rounded flex px-6 py-8 flex-col justify-center items-center">
        <IoIosCloseCircleOutline
          size={30}
          onClick={() => show(false)}
          className=" absolute  top-1 right-5 p-1"
        />
        <h1 className="text-lg font-semibold text-black  mt-4">
          Sure you want to delete this item from the list?
        </h1>

        <small>{item.title}</small>
        <h3 className=" font-light mb-4 text-sm text-red-400">
          Note: Deleted items cannot be undone
        </h3>
        {loading && (
          <RiLoader2Fill size={40} className="text-green-500 animate-spin" />
        )}
        <button
          onClick={async () => {
            setLoading(true);
            var resp = await onDeleteDoc(item.id);

            setLoading(false);
            show(false)
            refresh()
          }}
          className="px-3 py-1 self-end rounded mt-6 bg-green-400 text-white font-light"
        >
          <MdDelete className=" inline" /> Delete
        </button>
      </div>
    </div>
  );
};
