import React from "react";

const YearTwoHome = () => {
  return (
    <div className="w-full">
    <div className="home flex justify-center items-center" style={{ height: "150px", width: "100%" }}>
      
      </div>
      <div>
        <iframe
          title="SCL - Project Juriya - Year 2"
          width="100%"
          height="600"
          src="https://app.powerbi.com/view?r=eyJrIjoiNjY2NmJhZWUtZTFkNi00ZjVlLTlhZWQtMWNlODkzMjk0YTIwIiwidCI6ImQ3MTg2MGNiLTE4MWQtNDQ0Zi05MDBjLTkyMjJhZjg2MDYwZCJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </div>
  );
};

export default YearTwoHome;
