import { useState } from "react";
 


export default ()=>{

    var [file, setfile]=useState()

    const refreshfile=()=>{

        setfile('')
    }



    return {file, setfile, refreshfile}
}


 